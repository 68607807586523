<template>
  <div class="ml-empty">
    <div class="ml-empty__ico">
      <img src="../../../assets/img/ico/ico-empty-list.svg" alt="" />
    </div>

    <h4 class="ml-empty__title">{{ $t("mylist.emptyList") }}</h4>
    <div class="ml-empty__desc">
      {{ $t("mylist.emptyListSubtitle") }}
    </div>
    <!-- <div class="ml-empty__desc" v-show="isApp">
      Añade tu primer objeto con la opción <b>Añadir</b>. Si te faltan ideas, usa <b>Explorar</b>. 😉
    </div> -->
    <a @click.prevent="openConstructor">
      <button class="button button--accent button--lg button--max">
        {{ $t("generic.begin") }}
      </button>
    </a>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ListEmpty",
  computed: 
  {
    isApp()
    {
      if(this.$store.state.cookies.sourceApp == "true") return true;
      return false;
    }
  },
  methods: {
    openConstructor() {
      if (!this.$utils.isApp()) {
        this.$router.push({ name: "list-constructor" });
      } else {
        location.href = process.env.APP_URL + "constructor?source=app";
      }
    },
  },
};
</script>
